<template>
  <div class="screenRecord">
    <van-tabs v-model="active" title-active-color="#000" color="#0063ff">
      <van-tab title="今日放映">
        <!--        {{todayPlans}}-->
        <div
          class="planItem box"
          v-for="(plan, index) in todayPlans"
          :key="index"
        >
          <div class="planItem-dateTime">
            {{ plan.playDatetime }}({{ plan.playDatetime | getWeek }})
          </div>
          <div class="planItem-address">
            <van-icon name="location" size="20px" color="#50baff"></van-icon>
            {{ plan.playPlace }}
          </div>
          <div class="planItem-films">
            <div class="planItem-films-header">
              <div>排名</div>
              <div>影片名称</div>
              <!--              <div>-->
              <!--                影片时长-->
              <!--              </div>-->
            </div>
            <div
              class="planItem-films-body"
              v-for="(film, index) in plan.movieList"
              :key="film.id"
            >
              <div>
                <span :class="['mark' + (index + 1)]">{{ index + 1 }}</span>
              </div>
              <div>
                {{ film.filmName }}
              </div>
              <!--              <div>-->
              <!--                {{ film.length }}-->
              <!--              </div>-->
            </div>
          </div>
          <div class="planItem-filmPhoto">
            <span>拍照</span>
            <span>
              <img
                :src="plan.punchImage"
                width="80"
                height="80"
                alt="请上传图片"
                v-if="plan.punchImage"
              />
              <span v-else>请拍照上传</span>
              <van-icon
                name="photograph"
                size="80px"
                style="border-radius: 50px"
                @click="uploadTodayImg(plan)"
                color="#50baff"
              ></van-icon>
            </span>
          </div>
          <div class="planItem-filmPhoto" style="height: 50px">
            <span style="width: 30%"> 观看人数 </span>
            <span style="width: 60%">
              <!--              {{ plan.personNum }}-->
              <input
                type="number"
                name=""
                v-model="plan.personNum"
                id=""
                placeholder="观看时长超过30分钟"
              />
            </span>
          </div>
          <div class="submit">
            <van-button type="info" block round @click="submit(plan)"
              >{{ plan.subType === 'add' ? '提交' : '更新' }}
            </van-button>
          </div>
        </div>
        <div class="box" v-if="todayPlans.length < 1">
          <van-empty description="暂无放映记录"></van-empty>
        </div>
      </van-tab>
      <van-tab title="历史放映">
        <div
          class="planItem box"
          v-for="(plan, index) in plansHistory"
          :key="index"
        >
          <div class="box-lr">
            <div>
              <div class="planItem-dateTime">
                {{ plan.playDatetime }}({{ plan.playDatetime | getWeek }})
              </div>
              <div class="planItem-address">
                <van-icon
                  name="location"
                  size="20px"
                  color="#50baff"
                ></van-icon>
                {{ plan.playPlace }}
              </div>
            </div>
            <div class="submit">
              <van-button
                type="info"
                plain
                size="small"
                icon="edit"
                round
                @click="showUpdatePlan(plan)"
                >更 新
              </van-button>
            </div>
          </div>
        </div>
        <div class="box" v-if="plansHistory.length < 1">
          <van-empty description="暂无放映记录"></van-empty>
        </div>
      </van-tab>
    </van-tabs>
    <!--    提交按钮-->
    <!--    修改历史放映-->
    <van-popup
      v-model="showUpdatePlanPopup"
      position="bottom"
      :closeable="false"
      style="height: 90%"
    >
      <div class="box">
        <div class="planItem-dateTime">
          {{ updatePlan.playDatetime || '' }}({{
            updatePlan.playDatetime || '' | getWeek
          }})
        </div>
        <div class="planItem-address">
          <van-icon name="location" size="20px" color="#50baff"></van-icon>
          {{ updatePlan.playPlace }}
        </div>
        <div class="planItem-films">
          <div class="planItem-films-header">
            <div>排名</div>
            <div>影片名称</div>
          </div>
          <div
            class="planItem-films-body"
            v-for="(film, index) in updatePlan.movieList"
            :key="film.id"
          >
            <div>
              <span :class="['mark' + (index + 1)]">{{ index + 1 }}</span>
            </div>
            <div>
              {{ film.filmName }}
            </div>
          </div>
        </div>
        <div class="planItem-filmPhoto">
          <span>拍照</span>
          <span>
            <img
              :src="updatePlan.punchImage"
              width="80"
              height="80"
              v-if="updatePlan.punchImage"
            />
            <van-icon
              name="photograph"
              size="80px"
              style="border-radius: 50px"
              @click="uploadHistoryImg"
              color="#50baff"
            ></van-icon>
          </span>
        </div>
        <div class="planItem-filmPhoto">
          <span style="width: 30%"> 观看人数 </span>
          <span style="width: 70%">
            <input
              type="number"
              v-model="updatePlan.personNum"
              id=""
              placeholder="观看时长超过30分钟"
            />
          </span>
        </div>
        <div class="submit">
          <van-button type="info" block round @click="submitHistory(updatePlan)"
            >提交
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { api } from '@/api/index'
import common from '@/utils/common'

export default {
  name: 'index',
  data() {
    return {
      cameraImg: require('@/assets/icon/camera.svg'),
      active: 0,
      todayPlans: [],
      plansHistory: [],
      showUpdatePlanPopup: false,
      updatePlan: {},
      tmp: {},
    }
  },
  mounted() {
    this.initPage()
  },
  beforeMount() {},
  methods: {
    initPage() {
      this.getTodayPlan()
      this.getHistoryPlan()
    },
    // 获取今日放映记录
    getTodayPlan() {
      api.checkIn.getFilmTodayList({}).then((res) => {
        this.todayPlans = res || []
        this.todayPlans = res.map((item) => {
          if (item.personNum) {
            return {
              ...item,
              subType: 'update',
            }
          } else {
            return {
              ...item,
              subType: 'add',
            }
          }
        })
      })
    },
    // 获取历史放映记录
    getHistoryPlan() {
      api.checkIn
        .getFilmHistoryList({ pageNo: 1, pageSize: 10000 })
        .then((res) => {
          console.log(res)
          this.plansHistory = res.records
        })
    },
    // 显示更新历史放映弹窗
    showUpdatePlan(plan) {
      this.getHistoryPlanDetail(plan)
    },
    // 获取历史放映详细
    getHistoryPlanDetail(plan) {
      api.checkIn
        .getFilmHistoryDetail({ id: plan.id })
        .then((res) => {
          this.showUpdatePlanPopup = true
          console.log('将要修改历史放映', res)
          this.updatePlan = res
          this.updatePlan.punchImage = res.punchImage
        })
        .catch((err) => {
          this.Alert(err.message)
        })
    },

    // 提交今日放映打卡
    submit(plan) {
      if (
        !this.$store.state.userAddressLocation.latitude ||
        !this.$store.state.userAddressLocation.longitude
      ) {
        this.Alert('请打开定位并授权定位权限')
        return
      }
      const data = {
        filmtaskId: plan.id,
        lat: this.$store.state.userAddressLocation.latitude || '未知',
        lng: this.$store.state.userAddressLocation.longitude || '未知',
        personNum: plan.personNum || 0,
        punchImage: plan.punchImage,
        playDatetime: plan.playDatetime,
        playPlace: plan.playPlace,
        punchPlace: `${this.$store.state.userAddressLocation.cityName || '未知'}
        ${
          this.$store.state.userAddressLocation.region ||
          this.$store.state.userAddressLocation.district
        }`,
      }
      // 判断表单
      if (!data.punchImage) {
        this.Alert('请上传打卡照片')
        return
      }
      // 判断观影人数
      if (!data.personNum) {
        this.Alert('请输入观影人数')
        return
      }

      // this.Alert(JSON.stringify(data))
      // 判断执行更新还是添加
      if (plan.subType === 'add') {
        // 提交打卡
        api.checkIn.submitFilmToday(data).then((res) => {
          Dialog.alert({
            message: '打卡成功',
          })
            .then(() => {
              this.initPage()
            })
            .catch((err) => {
              Toast(err.toString())
            })
        })
      } else {
        api.checkIn.updateFilmHistory(data).then((res) => {
          Dialog.alert({
            message: '更新成功',
          })
            .then(() => {
              this.initPage()
            })
            .catch((err) => {
              Toast(err.toString())
            })
        })
      }
    },

    // 提交历史放映打卡
    submitHistory(plan) {
      console.log('plan', plan)
      // 判断表单
      if (
        !this.$store.state.userAddressLocation.latitude ||
        !this.$store.state.userAddressLocation.longitude
      ) {
        this.Alert('请打开定位并授权定位权限')
        return
      }

      const data = {
        filmtaskId: plan.id,
        lat: this.$store.state.userAddressLocation.latitude || '未知',
        lng: this.$store.state.userAddressLocation.latitude || '未知',
        personNum: plan.personNum || 0,
        punchImage: plan.punchImage,
        playDatetime: plan.playDatetime,
        playPlace: plan.playPlace,
        punchPlace: `${this.$store.state.userAddressLocation.cityName || '未知'}
        ${
          this.$store.state.userAddressLocation.region ||
          this.$store.state.userAddressLocation.district
        }`,
      }
      // 提交打卡
      // 判断表单
      if (!data.punchImage) {
        this.Alert('请上传打卡照片')
        return
      }
      // 判断观影人数
      if (!data.personNum) {
        this.Alert('请输入观影人数')
        return
      }
      api.checkIn.updateFilmHistory(data).then((res) => {
        Dialog.alert({
          message: '打卡修改成功',
        }).then(() => {
          this.showUpdatePlanPopup = false
          this.getHistoryPlan()
        })
      })
      // console.log("提交",data);
    },

    // 上传修改历史放映图片
    uploadHistoryImg(file) {
      //上传图片
      ZWJSBridge.chooseImage({
        upload: true,
      })
        .then((res) => {
          // console.log(222, res);
          this.tmp = res
          this.updatePlan.punchImage = res.picPath[0]
          this.$forceUpdate()
        })
        .catch((error) => {
          this.tmp = error
          console.log(error)
        })
    },

    uploadTodayImg(plan) {
      ZWJSBridge.chooseImage({
        upload: true,
      })
        .then((res) => {
          this.tmp = res
          plan.punchImage = res.picPath[0]
          this.$forceUpdate()
        })
        .catch((error) => {
          this.tmp = error
          console.log(error)
        })
    },
    Alert(message) {
      Dialog.alert({
        message: message,
      })
    },
  },
  filters: {
    textLength(value) {
      if (value.length > 10) {
        return value.substr(0, 10) + '...'
      } else {
        return value
      }
    },

    // 根据日期返回星期
    getWeek(value) {
      return common.getWeekByDate(new Date(value))
    },
  },
}
</script>

<style scoped lang="less">
.screenRecord {
  min-height: 100vh;
  background-color: #f6f7f8;
}

.box {
  width: 100%;
  //height: 283px;
  background: #ffffff;
  border-radius: @border-radius-base;
  margin: 5px auto;
  padding: 5px;
}

.box-lr {
  display: flex;
  justify-content: space-between;

  > div:first-child {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  > div:last-child {
    width: 22%;
    //border: 1px solid red;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.planScrollBox {
  border: 1px solid green;
  position: absolute;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
}

.planItem {
  //border: 1px solid rgba(56, 140, 206, 0.22);

  &-dateTime {
    height: 30px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC, serif;
    margin-bottom: 10px;
    font-weight: 500;
    color: #363a44;
    line-height: 40px;
  }

  &-address {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    font-weight: 400;
    color: #363a44;
    line-height: 20px;
    margin: 10px 0;
  }

  &-films {
    width: 336px;
    margin: 0 auto;

    &-header {
      width: 336px;
      height: 34px;
      background: #ebf3fe;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC, serif;
      font-weight: 500;
      color: #244e8a;
      line-height: 20px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      text-align: center;

      > div:first-child {
        width: 30%;
      }

      > div:nth-child(2) {
        width: 60%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > div:nth-child(3) {
        width: 30%;
        text-align: center;
      }
    }

    &-body {
      width: 336px;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC, serif;
      font-weight: 500;
      color: #244e8a;
      line-height: 40px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #f0f5fe;

      > div:first-child {
        width: 30%;
      }

      > div:nth-child(2) {
        width: 60%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > div:nth-child(3) {
        width: 30%;
        text-align: center;
      }
    }
  }

  //拍照
  &-filmPhoto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    height: 90px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #363a44;
    line-height: 40px;

    > span:nth-child(2) {
      //border: 1px solid  red;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input[type='number'] {
      //border: 1px solid red;
      border: transparent;
    }
  }
}

.submit {
  width: 90%;
  margin: 0 auto;
  height: 50px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mark1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 16px;
  height: 18px;
  background: linear-gradient(180deg, #8fece7 0%, #56d5cb 100%);
  box-shadow: 0 2px 3px 0 #caf2ef;
  border-radius: 3px;
  margin: 0 auto;
}

.mark2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 16px;
  height: 18px;
  background: linear-gradient(180deg, #abcbff 0%, #729cff 100%);
  box-shadow: 0 2px 3px 0 rgba(143, 185, 255, 0.4);
  border-radius: 3px;
  margin: 0 auto;
}

.mark3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 16px;
  height: 18px;
  background: linear-gradient(180deg, #d3b0ff 0%, #d26eff 100%);
  box-shadow: 0 2px 3px 0 #f6d3ff;
  border-radius: 3px;
  margin: 0 auto;
}
</style>
